import { default as indexKMbc9ewPCCMeta } from "/var/lib/jenkins/workspace/SistemaGestion-fe/pages/clientes/[...proyectos]/index.vue?macro=true";
import { default as cardXEB9CxwsZbMeta } from "/var/lib/jenkins/workspace/SistemaGestion-fe/pages/equipo/_views/card.vue?macro=true";
import { default as misEquipos8GGe8Gm7n0Meta } from "/var/lib/jenkins/workspace/SistemaGestion-fe/pages/equipo/_views/misEquipos.vue?macro=true";
import { default as todosEquiposgKKMvFsTBdMeta } from "/var/lib/jenkins/workspace/SistemaGestion-fe/pages/equipo/_views/todosEquipos.vue?macro=true";
import { default as indexlqACJLFu3dMeta } from "/var/lib/jenkins/workspace/SistemaGestion-fe/pages/equipo/index.vue?macro=true";
import { default as cardI1fjuVexWnMeta } from "/var/lib/jenkins/workspace/SistemaGestion-fe/pages/index/_views/card.vue?macro=true";
import { default as listTask6XOdM3Rhx3Meta } from "/var/lib/jenkins/workspace/SistemaGestion-fe/pages/index/_views/listTask.vue?macro=true";
import { default as peopleVeL4rt1icFMeta } from "/var/lib/jenkins/workspace/SistemaGestion-fe/pages/index/_views/people.vue?macro=true";
import { default as indexxwakBTVAPzMeta } from "/var/lib/jenkins/workspace/SistemaGestion-fe/pages/index/index.vue?macro=true";
import { default as indexEbCvhqE155Meta } from "/var/lib/jenkins/workspace/SistemaGestion-fe/pages/login/index.vue?macro=true";
import { default as reportexLoniHE8sFMeta } from "/var/lib/jenkins/workspace/SistemaGestion-fe/pages/reportes/reporte.vue?macro=true";
export default [
  {
    name: indexKMbc9ewPCCMeta?.name ?? "clientes-proyectos",
    path: indexKMbc9ewPCCMeta?.path ?? "/clientes/:proyectos(.*)*",
    meta: indexKMbc9ewPCCMeta || {},
    alias: indexKMbc9ewPCCMeta?.alias || [],
    redirect: indexKMbc9ewPCCMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/SistemaGestion-fe/pages/clientes/[...proyectos]/index.vue").then(m => m.default || m)
  },
  {
    name: cardXEB9CxwsZbMeta?.name ?? "equipo-_views-card",
    path: cardXEB9CxwsZbMeta?.path ?? "/equipo/_views/card",
    meta: cardXEB9CxwsZbMeta || {},
    alias: cardXEB9CxwsZbMeta?.alias || [],
    redirect: cardXEB9CxwsZbMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/SistemaGestion-fe/pages/equipo/_views/card.vue").then(m => m.default || m)
  },
  {
    name: misEquipos8GGe8Gm7n0Meta?.name ?? "equipo-_views-misEquipos",
    path: misEquipos8GGe8Gm7n0Meta?.path ?? "/equipo/_views/misEquipos",
    meta: misEquipos8GGe8Gm7n0Meta || {},
    alias: misEquipos8GGe8Gm7n0Meta?.alias || [],
    redirect: misEquipos8GGe8Gm7n0Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/SistemaGestion-fe/pages/equipo/_views/misEquipos.vue").then(m => m.default || m)
  },
  {
    name: todosEquiposgKKMvFsTBdMeta?.name ?? "equipo-_views-todosEquipos",
    path: todosEquiposgKKMvFsTBdMeta?.path ?? "/equipo/_views/todosEquipos",
    meta: todosEquiposgKKMvFsTBdMeta || {},
    alias: todosEquiposgKKMvFsTBdMeta?.alias || [],
    redirect: todosEquiposgKKMvFsTBdMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/SistemaGestion-fe/pages/equipo/_views/todosEquipos.vue").then(m => m.default || m)
  },
  {
    name: indexlqACJLFu3dMeta?.name ?? "equipo",
    path: indexlqACJLFu3dMeta?.path ?? "/equipo",
    meta: indexlqACJLFu3dMeta || {},
    alias: indexlqACJLFu3dMeta?.alias || [],
    redirect: indexlqACJLFu3dMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/SistemaGestion-fe/pages/equipo/index.vue").then(m => m.default || m)
  },
  {
    name: cardI1fjuVexWnMeta?.name ?? "index-_views-card",
    path: cardI1fjuVexWnMeta?.path ?? "//_views/card",
    meta: cardI1fjuVexWnMeta || {},
    alias: cardI1fjuVexWnMeta?.alias || [],
    redirect: cardI1fjuVexWnMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/SistemaGestion-fe/pages/index/_views/card.vue").then(m => m.default || m)
  },
  {
    name: listTask6XOdM3Rhx3Meta?.name ?? "index-_views-listTask",
    path: listTask6XOdM3Rhx3Meta?.path ?? "//_views/listTask",
    meta: listTask6XOdM3Rhx3Meta || {},
    alias: listTask6XOdM3Rhx3Meta?.alias || [],
    redirect: listTask6XOdM3Rhx3Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/SistemaGestion-fe/pages/index/_views/listTask.vue").then(m => m.default || m)
  },
  {
    name: peopleVeL4rt1icFMeta?.name ?? "index-_views-people",
    path: peopleVeL4rt1icFMeta?.path ?? "//_views/people",
    meta: peopleVeL4rt1icFMeta || {},
    alias: peopleVeL4rt1icFMeta?.alias || [],
    redirect: peopleVeL4rt1icFMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/SistemaGestion-fe/pages/index/_views/people.vue").then(m => m.default || m)
  },
  {
    name: indexxwakBTVAPzMeta?.name ?? "index",
    path: indexxwakBTVAPzMeta?.path ?? "/",
    meta: indexxwakBTVAPzMeta || {},
    alias: indexxwakBTVAPzMeta?.alias || [],
    redirect: indexxwakBTVAPzMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/SistemaGestion-fe/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexEbCvhqE155Meta?.name ?? "login",
    path: indexEbCvhqE155Meta?.path ?? "/login",
    meta: indexEbCvhqE155Meta || {},
    alias: indexEbCvhqE155Meta?.alias || [],
    redirect: indexEbCvhqE155Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/SistemaGestion-fe/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: reportexLoniHE8sFMeta?.name ?? "reportes-reporte",
    path: reportexLoniHE8sFMeta?.path ?? "/reportes/reporte",
    meta: reportexLoniHE8sFMeta || {},
    alias: reportexLoniHE8sFMeta?.alias || [],
    redirect: reportexLoniHE8sFMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/SistemaGestion-fe/pages/reportes/reporte.vue").then(m => m.default || m)
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/"
  }
]